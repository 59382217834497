@import '~@transverse/digitalsystems-library/dist/styles/core.css';

@tailwind base;
@tailwind utilities;
@tailwind components;

@layer components {
  .card-shadow {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

.gradiant-border {
  position: relative;
}
.gradiant-border::before {
  content: '';
  position: absolute;
  inset: 0;
  border: 3px solid transparent;
  border-radius: 100%;
  background: linear-gradient(90deg, #ff0000 0%, #ce41ff 100%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.CircularProgress .textContent {
  display: none;
}

.min-screen-height {
  min-height: calc(100vh - 45px);
}

input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.disabled-input {
  color: #525252;
  -webkit-text-fill-color: #525252;
}
